<template>
  <div>
    <div
      v-if="hasToken"
      class="s-profile flex"
    >
      <div class="s-profile-left-part">
        <PageHeaderProfilePageNavigation />
      </div>
      <div class="s-profile-right-part">
        <PageHeaderTopNavbar variant="profile">
          <template #left>
            <div class="s-profile-header-title text-2xl text-white pl-10">
              <span class="hidden md:block">Dashboard</span>
            </div>
          </template>
        </PageHeaderTopNavbar>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import { $api } from '@/utils/api'

  export default {
    name: 'ProfileLayout',
    computed: {
      hasToken() {
        return this.$store.getters.hasToken
      }
    },
    mounted() {
      this.$nextTick(async () => {
        if (!localStorage.getItem('token')) {
          return this.$router.push('/')
        }

        await this.getUser()
        this.$store.commit('setToken', localStorage.getItem('token'))
      })
    },
    methods: {
      async getUser() {
        try {
          const userRequest = await $api.ProfileRequests.getUser()

          this.$store.commit('setUser', userRequest)
        } catch (e) {}
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-profile {
    margin-left: 260px;
    @media screen and (max-width: 1140px) {
      margin-left: 260px;
    }
    @media screen and (max-width: 968px) {
      margin-left: 64px;
    }

    .s-profile-left-part {
      width: 260px;
      //background: linear-gradient(312.6deg, #f  ffcd2 0%, #e5f5f7 22.29%, #fbe8f0 41.25%, #fff 52.28%), #fff;
      box-shadow: 0 2px 11px rgba(0, 0, 0, 0.1);
      @apply h-screen fixed top-0 left-0;

      @media screen and (max-width: 1140px) {
        max-width: 260px;
        width: 100%;
      }

      @media screen and (max-width: 968px) {
        width: 64px;
      }
    }

    .s-profile-right-part {
      width: 100%;
    }
  }
</style>
