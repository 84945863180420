<template>
  <div class="s-profile-navigation flex">
    <NuxtLink :to="'/'"
      ><img
        src="@/assets/icon/png/logo.png"
        class="s-profile-logo"
        alt="logo"
    /></NuxtLink>
    <div class="s-profile-logo-tablet flex">
      <NuxtLink :to="'/'"
        ><img
          src="@/assets/icon/png/black-profile-tablet.png"
          alt="logo"
      /></NuxtLink>
    </div>
    <div class="s-profile-return flex items-center">
      <NuxtLink
        class="s-profile-return-text"
        to="/shop"
      >
        <span>&lt;</span>
        <span class="content">Return to Shop</span>
      </NuxtLink>
    </div>
    <div
      v-for="(item, index) in menuItems"
      :key="index"
      class="s-profile-items flex"
      @click="item.route ? goToPage(item.route) : logout()"
    >
      <img
        :src="item.icon"
        alt=""
      />
      <div
        class="item-title"
        :class="{ active: $route.path.includes(item.route) }"
      >
        {{ item.title }}
      </div>
    </div>

    <div
      class="s-profile-items flex"
      @click="logout"
    >
      <img
        src="/images/icons/logout.svg?url"
        alt=""
      />
      <div class="item-title">Logout</div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'ProfileNavigation',
    data: () => ({
      menuItems: [
        {
          route: '/profile/dashboard',
          title: 'dashboard',
          icon: '/images/icons/dashboard.svg',
          active: false
        },
        {
          route: '/profile/orders',
          title: 'orders',
          icon: '/images/icons/orders.svg',
          active: false
        },
        {
          route: '/profile/loyalty',
          title: 'loyalty',
          icon: '/images/icons/loyalty.svg',
          active: false
        },
        {
          route: '/profile/medical',
          title: 'medical',
          icon: '/images/icons/medical.svg',
          active: false
        },
        {
          route: '/profile/payments',
          title: 'payments',
          icon: '/images/icons/payments.svg',
          active: false
        },
        {
          route: '/profile/account-details',
          title: 'account',
          icon: '/images/icons/user.svg',
          active: false
        },
        {
          route: '/profile/address',
          title: 'address',
          icon: '/images/icons/address.svg',
          active: false
        }
      ]
    }),

    methods: {
      ...mapActions('auth', ['xLogout']),

      goToPage(path) {
        if (path) {
          this.$router.push({ path })
        }
      },

      async logout() {
        await this.xLogout()
        this.$router.push('/')
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-profile-navigation {
    flex-direction: column;
    width: 260px;

    @media screen and (max-width: 1140px) {
      width: 100%;
    }

    @media screen and (max-width: 968px) {
      width: 64px;
    }

    .s-profile-logo {
      width: 200px;
      height: 29px;
      margin: 23px 29px 22px 31px;

      @media screen and (max-width: 968px) {
        display: none;
      }
    }

    .s-profile-logo-tablet {
      display: none;

      @media screen and (max-width: 968px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 72px;
        width: 100%;

        img {
          height: 34px;
        }
      }
    }

    .s-profile-return {
      padding: 13px 0 17px 30px;
      align-items: center;
      border-top: 1px solid #e7ebee;

      @media screen and (max-width: 968px) {
        padding: 0;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .s-profile-return-text {
        @apply font-alt;
        font-style: normal;
        font-weight: 500;
        font-size: 12.8px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #7c93a5;

        span {
          margin-right: 8px;
        }

        .content {
          text-decoration: underline;

          @media screen and (max-width: 968px) {
            display: none;
          }
        }
      }
    }

    .s-profile-items {
      padding-left: 32px;
      align-items: center;
      cursor: pointer;
      border-top: 1px solid #e7ebee;

      @media screen and (max-width: 968px) {
        width: 100%;
        height: 60px;
        padding-left: 0;
        display: flex;
        justify-content: center;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 34px;

        @media screen and (max-width: 968px) {
          margin-right: 0;
        }
      }

      .item-title {
        @apply font-main;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12.8px;
        line-height: 53px;
        display: flex;
        letter-spacing: 2px;
        color: #7c93a5;

        @media screen and (max-width: 968px) {
          display: none;
        }

        &.active {
          color: #002d52;
        }
      }
    }
  }
</style>
